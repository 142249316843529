.panTable{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panTableBody{
    width: 100%;
    /* height: 800px; */
}

.tableCol{
    text-align: center;
    white-space: nowrap;
    min-width: 160px;
}
/* .tableCol:last-child{
    width: 200px;
} */
/* @media screen and (max-width: 2355px) {
    .panTableBody{
        width: 100%;
        height: 900px;
    }
}

@media screen and (max-width: 1333px) {
    .panTableBody{
        width: 100%;
        height: 1100px;
    }
}

@media screen and (max-width: 1238px) {
    .panTableBody{
        width: 100%;
        height: 1200px;
    }
}

@media screen and (max-width: 1070px) {
    .panTableBody{
        width: 100%;
        height: 1350px;
    }
} */
/* .pagination{
    position: absolute;
    top: 95%;
    left: 60%;
    transform: translate(-95%, -55%);
} */



/* .tableCol:nth-child(1){
    width: 11%;
}
.tableCol:nth-child(2){
    width: 15%;
}

.tableCol:nth-child(3){
    width: 15%;
}

.tableCol:nth-child(4){
    width: 15%;
}

.tableCol:nth-child(5){
    width: 11%;
}

.tableCol:nth-child(6){
    width: 10%;
}

.tableCol:nth-child(7){
    width: 10%;
}
.tableCol:nth-child(8){
    width: 13%;
} */