.his_th:nth-child(1){
    overflow-wrap: anywhere;
}

.his_th:nth-child(2){
    overflow-wrap: anywhere;
}

.his_th:nth-child(3){
    overflow-wrap: anywhere;
}

.his_th:nth-child(4){
    overflow-wrap: anywhere;
}

th{
    text-align: center;
}

.modal-body{
    padding : 0;
}