.operation{
    margin: 1em 0;
}

textarea {
    resize: none !important;
}

.check{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding-bottom: 8px !important; */
}

.modal-content{
    padding: 10px 30px;
}

.validity-col{
    padding-left: 23px !important;
}

.form-group{
    padding: 0 0 0 20px;
}

.comment{
    padding: 0 0 0 18px !important;
    margin-bottom: 10px;
}
/* 
.formUnit-shortTitle{
    height: 5.3em;
}

.formUnit-lvCode{
    height: 5.3em;
}

.formUnit-beCode{
    height: 5.3em;
}

.formUnit-pvCode{
    height: 5.3em;
}

.formUnit-Va{
    height: 5.3em;
}

.test{
    height: 5.3em;
}

.check{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 8px !important;
}

textarea {
    resize: none !important;
}


.modal-content{
    padding: 10px 30px;
}

.validity-col{
    padding-left: 23px !important;
}

.form-group{
    padding: 0 0 0 20px;
}

.comment{
    padding: 0 0 0 18px !important;
    margin-bottom: 10px;
}

.modal-footer{
    padding-right: 0 !important;
}

.invalid-feedback{
    font-size: 1em !important;
} */