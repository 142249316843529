td{
    text-align: center;
}

.wrapCol{
    min-width: 300px;
    max-width: 500px;
    overflow-wrap: break-word;
}

.noWrapCol{
    white-space: nowrap;
    min-width: 180px;
}

.actionCol{
    min-width: 200px;
}

.actionButton{
    margin: 0 2px;
}