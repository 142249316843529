.panHeader{
    display: flex;
    justify-content: space-between;
    height: 75px;
    padding: 4px;
    border-bottom: 1px rgb(182, 181, 181) solid;
}

.panHeader .block1{
    width: 175px;
    align-self: center
}

.panHeader .block1 img{
    height:100%;
    width: 100%;
}

.panHeader .block2{
    text-align: center;
    width: 500px;
    font-weight: bolder;
    align-self: center
}

.panHeader .block3{
    text-align: center;
    /* float: right; */
    min-width: 160px;
    padding-right: 20px;
    align-self: center
}

.panHeader .menu{
    font: 14;
    padding-left: 10px;
}