.find{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.findUser{
    width: 350px !important;
}

.panTable{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tableCol{
    text-align: center;
    white-space: nowrap;
    min-width: 180px;
}

.date-input-group{
    display: flex;
    width: 160px;
}

.pagination{
    margin-top: 30px;
}

.info{
    width: 18px;
    height: 18px;
    margin: auto 10px;
    cursor: pointer;
}

.info-img{
    width: 100%;
    height: 100%;
}

